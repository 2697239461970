import { PASSWORD, AUTHENTICATION, GENERAL } from '../constants/errorMessage';

export function oktaErrorService(err: any): string {
  if (err.errorCauses && err.errorCauses.length > 0) {
    switch (err.errorCauses[0].errorSummary) {
      case 'password.value: Password cannot be your current password':
        return PASSWORD.NOT_CURRENT_PASSWORD;
      case 'User is currently locked out.':
        return AUTHENTICATION.LOCKED_OUT;
    }
  }
  if (err.errorSummary) {
    switch (err.errorSummary) {
      case 'Your account has been locked out. Please contact an administrator':
        return AUTHENTICATION.LOCKED_OUT;
      case 'Change password not allowed on specified user.':
        return AUTHENTICATION.CANNOT_CHANGE_PASSWORD;
      case 'Authentication failed':
        return AUTHENTICATION.AUTH_ERROR;
    }
  }
  if (err.errorCode) {
    switch (err.errorCode) {
      case 'NOT_FOUND':
      case 'E0000007': // Okta not found error. Receive this when user doesn't exist
        return AUTHENTICATION.AUTH_ERROR;
      case 'SUSPENDED':
        return AUTHENTICATION.SUSPENDED;
      case 'DEPROVISIONED':
        return AUTHENTICATION.DEPROVISIONED;
      case 'PROVISIONED':
        return AUTHENTICATION.PROVISIONED;
      case 'STAGED':
        return AUTHENTICATION.STAGED;
    }
  }
  return GENERAL.UNKNOWN_ERROR;
};

export default { oktaErrorService };
