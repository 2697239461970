import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import '../../styles/LoginPage.scss';

class LoginText extends Component {

  render() {
    return (
      <div className="cp-message">
        <Typography variant="body1" gutterBottom>
          The LightHouse system helps healthcare leaders make better decisions through an easy to use and intuitive
          reporting interface.
          This portal is the project management component of the LightHouse system where you can get the latest files,
          tasks,
          and updates for your projects.


                <br />
          <br /> Questions? Please contact your AnalysisWorks representative, or email&nbsp;
            <a href="mailto:help@analysisworks.com">help@analysisworks.com</a>.
            </Typography>
      </div>
    )
  }

}

export default LoginText;
