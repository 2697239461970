import { PASSWORD } from '../constants/errorMessage';
import regex from '../constants/regex'

interface PasswordSuccess {
  errorSummary: string;
  success: boolean;
}

export function validatePassword(username: string, password: string, confirmPassword: string): PasswordSuccess {
  if (username.toLowerCase().includes(password)) {
    return { success: false, errorSummary: PASSWORD.CANNOT_CONTAIN_USERNAME };
  } else if (password !== confirmPassword) {
    return { success: false, errorSummary: PASSWORD.MUST_MATCH };
  } else if (!regex.PASSWORD.test(password)) {
    return { success: false, errorSummary: PASSWORD.REQUIREMENTS_NOT_MET };
  }
  return { success: true, errorSummary: '' };
}

export default { validatePassword };
