import React, { Component } from 'react';
import '../../styles/LoginPage.scss';
// Material UI
import Grid from '@material-ui/core/Grid';
//Components
import LoginBox from './LoginBox';
import LoginText from './LoginText';
import { getLocalStorageByKey, setLocalStorageByKey } from '../../util/storage';
import { isValidSessionToken } from '../../util/token';
const axios = require('axios');
class LoginPage extends Component<{}, {
  loaded: Boolean,
  errorMessage: string,
  app: string,
  redirectUrl: string
}> {

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      errorMessage: '',
      app: 'cp',
      redirectUrl: ''
    }
  }

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const logout = urlParams.get('Logout');
    const app = urlParams.get('App');
    const redirectUrl = urlParams.get('RedirectUrl');
    if (app && redirectUrl) {
      this.setState({ ...this.state, app, redirectUrl });
      // Set redirectUrl and redirectApp to local storage on callback and logout
      // Will save redirect information whenever query params present
      setLocalStorageByKey('redirectUrl', redirectUrl);
      setLocalStorageByKey('app', app);
    }

    window.history.pushState({}, document.title, '/');

    if (logout != null) {
      if (logout === 'failed') {
        // DEV_TEAM determine what to do with localstorage keys when logout fails
        this.setState({
          ...this.state,
          errorMessage: 'A logout error has occurred. Please clear your cache and cookies and refresh the page to log yourself out of all your applications.'
        });
      }

      localStorage.removeItem('sessionToken');
      this.setState({ loaded: true });
      window.history.pushState({}, document.title, '/');
    }

    getLocalStorageByKey('redirectUrl').then(redirectUrl => {
      if (redirectUrl != null) {
        this.setState({ redirectUrl });
      }
    });

    getLocalStorageByKey('app').then(app => {
      if (app != null) {
        this.setState({ app });
      }
    });

    getLocalStorageByKey('sessionToken').then(sessionToken => {
      const baseUrl = process.env['REACT_APP_API_URL'];

      if (isValidSessionToken(sessionToken)) {
        let appValue = this.state.app;
        let redirectUrlValue = this.state.redirectUrl;

        return axios.post(baseUrl + '/auth/redirect', {
          sessionToken,
          app: appValue,
          redirectUrl: redirectUrlValue
        }).then((res: any) => {
          window.location.href = res.data.url
        })
      } else {
        localStorage.removeItem('sessionToken');
        this.setState({ loaded: true })
      }
    });
  }

  render() {
    return (
      <div >
        {this.state.loaded ? (
          <div className="login">
            <Grid
              className="login-box-container"
              container
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Grid item xs={4}>
                <LoginText />
              </Grid>
              <Grid item xs={4}>
                <LoginBox errorMessage={this.state.errorMessage}
                  app={this.state.app}
                  redirectUrl={this.state.redirectUrl} />
              </Grid>
            </Grid>
          </div>
        ) : (<div className="load" />)
        }
      </div >
    )
  }

}

export default LoginPage;
