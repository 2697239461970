export const PASSWORD = {
  REQUIREMENTS_NOT_MET: 'Password requirements were not met. Min 8 characters, at least one upper case, and a number.',
  CANNOT_CONTAIN_USERNAME: 'Password cannot contain your username.',
  MUST_MATCH: 'Passwords need to match.',
  NOT_CURRENT_PASSWORD: `Password cannot be your current password.`
}

export const AUTHENTICATION = {
  AUTH_ERROR: `Authentication failed. Please ensure your username and password are correct.
  If the problem persists, please contact your AnalysisWorks representative or email help@analysisworks.com.`,
  LOCKED_OUT: `Your account has been locked out. Please contact your AnalysisWorks representative or email help@analysisworks.com for support.`,
  CANNOT_CHANGE_PASSWORD: `We are unable to change your password at this time. Your account may be locked out. Please contact your AnalysisWorks representative or email help@analysisworks.com for support.`,
  SUSPENDED: `Your account has been suspended. Please contact your AnalysisWorks representative or email help@analysisworks.com for support`,
  DEPROVISIONED: `Your account has been deactivated. Please contact your AnalysisWorks representative or email help@analysisworks.com for support.`,
  PROVISIONED: `Your account has not yet been activated. Check your email for an activation link, contact your AnalysisWorks representative or email help@analysisworks.com for support.`,
  STAGED: `Your account is staged. Please contact your AnalysisWorks representative or email help@analysisworks.com to finish setting up your account`,
}

export const GENERAL = {
  UNKNOWN_ERROR: `An error has occurred with your request. Please refresh the page and try again. If the problem persists, contact your Analysisworks representative or email help@analysisworks.com.`,
}
export default { PASSWORD, AUTHENTICATION, GENERAL }
