import React from 'react';
import LoginPage from './components/LoginPage/LoginPage';
const App: React.FC = () => {
  return (
    <div>
      <LoginPage />
    </div>
  );
}

export default App;
