import React, { Component } from 'react';
import { Lens } from '@material-ui/icons'

import '../../styles/dot-loader.scss';
class DotLoader extends Component<{}> {

  render() {
    return (
      <div className="uploading">
        <Lens className="lens" />
        <Lens className="lens" />
        <Lens className="lens" />
        <Lens className="lens" />
        <Lens className="lens" />
      </div>

    )
  }
}

export default DotLoader;
