import React, { Component } from 'react';

// Styles
import '../../styles/LoginPage.scss';

// Material UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { VpnKey } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';

// Local
import DotLoader from '../shared/dot-loader';
import SuccessRedirect from '../shared/success-redirect';
import { validatePassword } from '../../services/password.service';
import { oktaErrorService } from '../../services/oktaError.service';
const axios = require('axios');

type MyProps = {
  goBack: any,
  app: string,
  redirectUrl: string,
  tempPassword: string,
  login: any,
  username: string
};
type MyState = {
  formControls: {
    newPassword: string,
    confirmPassword: string,
  },
  errors: {
    login: string
  },
  success: boolean,
  loading: boolean,
};

class ResetTempPassword extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.state = {
      formControls: {
        newPassword: '', confirmPassword: '',
      },
      errors: {
        login: '',
      },
      success: false,
      loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goBackToSignIn = this.goBackToSignIn.bind(this);
  }

  handleSubmit(event: any) {
    this.setState({
      ...this.state,
      loading: true
    });
    this.setNewPassword();
    event.preventDefault();
  }

  goBackToSignIn = () => {
    this.props.goBack();
  }

  async setNewPassword() {
    const validate = validatePassword(this.props.username, this.state.formControls.newPassword, this.state.formControls.confirmPassword);
    if (!validate.success) {
      this.setState({
        ...this.state,
        loading: false,
        errors: {
          ...this.state.errors,
          login: validate.errorSummary,
        }
      });
    }
    else {
      const baseUrl = process.env['REACT_APP_API_URL'];
      await axios.post(`${baseUrl}/password/`, {
        userInfo: this.props.username,
        oldPassword: this.props.tempPassword,
        newPassword: this.state.formControls.newPassword,
        app: this.props.app,
        redirectUrl: this.props.redirectUrl,
      }).then(async (res: any) => {
        if (res.data.errorSummary) {
          const errorMessage = oktaErrorService(res.data);

          this.setState({
            ...this.state,
            loading: false,
            errors: {
              ...this.state.errors,
              login: errorMessage
            }
          })
        }
        else {
          this.setState({
            ...this.state,
            loading: false,
            success: true,
          })
          await this.props.login(this.props.username, this.state.formControls.newPassword);
        }
      });
    }
  }

  handleChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      errors: {
        login: '',
      },
      formControls: {
        ...this.state.formControls,
        [name]: value
      },
    });
  }

  renderTempPassword() {
    return (
      < div className="login-input" >
        <p>Please input your new password</p>
        <form onSubmit={this.handleSubmit} className="login-input">
          <FormControl className="form-box" >
            <TextField
              id="password"
              label="New Password"
              required
              name="newPassword"
              value={this.state.formControls.newPassword}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              type="password"
              placeholder="New Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              required
              name="confirmPassword"
              value={this.state.formControls.confirmPassword}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              type="password"
              placeholder="Confirm Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
            {!this.state.loading ?
              <div className="login-button-container">
                <Button
                  type="submit"
                  name="continue"
                  variant="contained"
                  size="large"
                  classes={{ label: 'login-button-label' }}
                  className="login-button">
                  Reset Password
                        </Button>
              </div> : null}
          </FormControl>
          <div className="error-messages">
            {this.state.errors.login}
          </div>
        </form>
        {this.state.loading ? <DotLoader /> : <button className="forgot-button" onClick={this.goBackToSignIn}>Back to Sign In</button>}
      </div >
    );
  }

  render() {
    return (
      <div className="login-input">
        {!this.state.success ? this.renderTempPassword() : <SuccessRedirect />}
      </div>
    );
  }
}

export default ResetTempPassword;
