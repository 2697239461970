import React, { Component } from 'react';
import DotLoader from './dot-loader';

import '../../styles/dot-loader.scss';
import '../../styles/LoginPage.scss';

class SuccessRedirect extends Component<{}> {

  render() {
    return (
      < div className="success-message" >
        <div>Successfully reset password.</div>
        <div className="redirecting">Redirecting </div>
        <div className="dot-loader-success"><DotLoader /></div>
      </div >
    );
  }
}

export default SuccessRedirect;
