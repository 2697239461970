import React, { Component } from 'react';

import '../../styles/dot-loader.scss';
import '../../styles/LoginPage.scss';

class SuccessEmailReset extends Component<{}> {

  render() {
    return (
      < div className="success-message" >
        <div>Please check your email for a reset password link and follow the instructions.
          <br /> If you did not receive an email, please contact your AnalysisWorks representative, or email help@analysisworks.com.  <br /><br /> </div>
      </div >
    );
  }
}

export default SuccessEmailReset;
