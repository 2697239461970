export const getLocalStorageByKey = (key: string): Promise<string | null> => {
  return Promise.resolve().then(() => {
    return localStorage.getItem(key);
  })
};

export const setLocalStorageByKey = (key: string, value: string): Promise<void> => {
  return Promise.resolve().then(function () {
    localStorage.setItem(key, value);
  });
}

export default { getLocalStorageByKey, setLocalStorageByKey };
