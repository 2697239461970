import React, { Component } from 'react';
//Styles
import '../../styles/LoginPage.scss';
// Images
import lh_logo from '../../assets/lh_logo_hq_sm.png';
import aw_logo from '../../assets/logo.png';
// Material UI
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AccountCircle, VpnKey } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';

// LOCAL
import ResetPassword from './ResetPassword';
import ResetTempPassword from './ResetTempPassword';
import { GENERAL } from '../../constants/errorMessage';
import regex from '../../constants/regex';
import { oktaErrorService } from '../../services/oktaError.service';
import { setLocalStorageByKey } from '../../util/storage';
const axios = require('axios');
type MyProps = {
  errorMessage: string,
  app: string,
  redirectUrl: string
};

type MyState = {
  formControls: {
    username: string, password: string,
  },
  errors: {
    username: string, password: string, login: string,
  },
  message: string,
  tempPassword: string,
  resetPasswordWithEmailLink: boolean,
  resetTempPassword: boolean
};


class LoginBox extends Component<MyProps, MyState> {

  constructor(props: any) {
    super(props);
    this.state = {

      formControls: {
        username: '', password: '',
      },
      errors: {
        username: '', password: '', login: '',
      },
      message: '',
      tempPassword: '',
      resetPasswordWithEmailLink: false,
      resetTempPassword: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgetPasswordStep = this.handleForgetPasswordStep.bind(this);
    this.login = this.login.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  submitForm = () => {
    this.login(this.state.formControls.username, this.state.formControls.password);
  }

  goBack = () => {
    this.setState({
      ...this.state, resetPasswordWithEmailLink: false, resetTempPassword: false,
      errors: {
        username: '', password: '', login: '',
      }
    });
  }

  handleChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      errors: {
        username: '', password: '', login: '',
      },
      formControls: {
        ...this.state.formControls,
        [name]: value
      },
    });
  }

  async login(username: string, password: string) {
    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        login: '',
      }
    })
    const baseUrl = process.env['REACT_APP_API_URL'];
    let redirectUrl = this.props.redirectUrl;
    let app = this.props.app;

    return axios.post(`${baseUrl}/auth/login`, {
      username: username,
      password: password,
      app,
      redirectUrl
    }).then((res: any) => {

      if (res.data.resetPassword) {
        this.setState({
          ...this.state,
          tempPassword: password,
          resetTempPassword: true,
          resetPasswordWithEmailLink: false
        });
      } else if (res.data.errorSummary) {
        const error = oktaErrorService(res.data);
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            login: error
          }
        })
      } else if (res.data.url != null && res.data.sessionToken != null) {
        setLocalStorageByKey('sessionToken', res.data.sessionToken).then(() => {
          this.setState({ message: res['message'] });
          window.location.href = res.data.url;
        });
      } else {
        // unknown error has occurred.
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            login: GENERAL.UNKNOWN_ERROR
          }
        });
      }

    }).catch((err: any) => {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          login: GENERAL.UNKNOWN_ERROR
        }
      })
    })
  }

  handleSubmit(event: any) {
    this.validateFields();
    event.preventDefault();
  }

  handleForgetPasswordStep(event: any) {
    this.setState({
      ...this.state,
      resetPasswordWithEmailLink: true,
      resetTempPassword: false,
    });
    event.preventDefault();
  }

  validateFields() {
    let isValid = true;
    this.setState({
      ...this.state,
      errors: {
        username: '', password: '', login: '',
      }
    })
    let usernameError = '';
    let passwordError = '';
    if (this.state.formControls.password === '' || !this.state.formControls.password) {
      passwordError = 'Please enter a password';
      isValid = false;
    }
    if (this.state.formControls.username === '' || !this.state.formControls.username || !(regex.USERNAME.test(this.state.formControls.username))) {
      usernameError = 'Please enter a valid email';
      isValid = false;
    }
    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        username: usernameError,
        password: passwordError
      }
    })
    if (isValid) {
      this.submitForm();
    }
  }

  render() {
    return (
      <div>
        <Box >
          <Grid
            container
            className="login-box"
            direction="column"
            alignItems="center"
            justify="space-evenly"
          >
            <Grid className="header-container"
              container
              direction="column"
              justify="center"
              alignItems="center">
              <div className="lighthouse-logo">
                <img
                  alt='LightHouse'
                  src={lh_logo} />
              </div>
            </Grid>

            <Grid
              className="login-body"
              container
              direction="column"
              justify="space-evenly"
              alignItems="center">
              {this.state.resetPasswordWithEmailLink ? <ResetPassword
                username={this.state.formControls.username}
                goBack={this.goBack}
                app={this.props.app}
                redirectUrl={this.props.redirectUrl}
                login={this.login} /> : null}

              {this.state.resetTempPassword ? <ResetTempPassword
                goBack={this.goBack}
                tempPassword={this.state.tempPassword}
                app={this.props.app}
                redirectUrl={this.props.redirectUrl}
                login={this.login}
                username={this.state.formControls.username}
              /> : null}
              <form onSubmit={this.handleSubmit}
                className="login-input"
              >
                <Grid
                  container
                  direction="column"
                  justify="space-evenly"
                  alignItems="center">
                  <FormControl className="form-box" >
                    {!this.state.resetTempPassword && !this.state.resetPasswordWithEmailLink &&
                      (<TextField
                        id="username"
                        name="username"
                        required
                        value={this.state.formControls.username}
                        onChange={this.handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                        error={this.state.errors.username !== ''}
                        className="input-color"
                        label="User Name"
                        margin="normal"
                        variant="outlined" />)}

                    {!this.state.resetPasswordWithEmailLink && !this.state.resetTempPassword &&
                      (<TextField
                        id="password"
                        label="Password"
                        required
                        name="password"
                        value={this.state.formControls.password}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        error={this.state.errors.password !== ''}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKey />
                            </InputAdornment>
                          ),
                        }}
                      />)
                    }
                    <div className="login-button-container">
                      {!this.state.resetPasswordWithEmailLink && !this.state.resetTempPassword ?
                        < Button
                          type="submit"
                          name="continue"
                          variant="contained"
                          size="large"
                          classes={{ label: 'login-button-label' }}
                          className="login-button">Sign In
                        </Button>
                        : null}
                    </div>
                  </FormControl>
                </Grid>
              </form>
            </Grid>
            {!this.state.resetPasswordWithEmailLink && !this.state.tempPassword ?
              <div className="error-messages">
                {this.state.errors.username}
                {this.state.errors.password}
                {this.state.errors.login}
                {this.props.errorMessage}
              </div> : null}
            {!this.state.resetPasswordWithEmailLink && !this.state.resetTempPassword &&
              (<Grid className="password-reset-box"
                container
                direction="column"
                justify="center"
                alignItems="center">

                <Grid className="password-reset-box"
                  container
                  direction="column"
                  justify="center"
                  alignItems="center">
                  <div className="password-reset-text">If you have received a temporary password from AnalysisWorks,
                    enter your username and temporary password above.</div>
                  <button className="forgot-button" onClick={this.handleForgetPasswordStep}>Forgot Password?</button>
                  <div className="password-reset-text">Click the above link to reset your LightHouse password.</div>
                </Grid>
              </Grid>)
            }
            <Grid className="footer-box"
              container
              direction="column"
              justify="center"
              alignItems="center">
              <div className="powered-by">
                <Typography variant="caption">  Powered By </Typography>
              </div>
              <div className="aw-logo">
                <img
                  alt='AW Logo'
                  src={aw_logo} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div >
    )
  }
}

export default LoginBox;
