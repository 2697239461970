import React, { Component } from 'react';

// Styles
import '../../styles/LoginPage.scss';

// Material UI
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AccountCircle } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';

// Local Imports
import { GENERAL } from '../../constants/errorMessage'
import DotLoader from '../shared/dot-loader';
import SuccessEmailReset from '../shared/success-email-reset';

const axios = require('axios');

type MyProps = {
  goBack: any,
  username: string,
  app: string,
  redirectUrl: string,
  login: any
};
type MyState = {
  formControls: {
    username: string
  },
  errors: {
    username: string, login: string
  },
  step: number;
  passwordSuccess: boolean;
  loading: boolean;
};

class ResetPassword extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      formControls: {
        username: ''
      },
      errors: {
        username: '', login: '',
      },
      step: 1,
      passwordSuccess: false,
      loading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBackToSignIn = this.goBackToSignIn.bind(this);
  }

  goBackToSignIn = () => {
    this.props.goBack();
  }

  handleChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      errors: {
        username: '', login: '',
      },
      formControls: {
        ...this.state.formControls,
        [name]: value
      },
    });
  }

  handleSubmit(event: any) {
    this.setState({
      ...this.state,
      loading: true,
      errors: {
        username: '',
        login: '',
      }
    });
    switch (this.state.step) {
      case 1:
        this.submitEmailRequest();
        break;
    }
    event.preventDefault();

  }

  async submitEmailRequest() {
    const baseUrl = process.env['REACT_APP_API_URL'];
    await axios.post(`${baseUrl}/password/sendEmail`, {
      email: this.state.formControls.username
    }).then((res: any) => {
      this.setState({
        ...this.state,
        loading: false,
        step: 2
      });
    }).catch((err: any) => {
      this.setState({
        ...this.state,
        loading: false,
        errors: {
          ...this.state.errors,
          login: GENERAL.UNKNOWN_ERROR,
        },
        step: 2
      });
    });
  }


  step1Render() {
    return (
      <div className="login-input">
        <p>Please enter your email</p>
        <TextField
          id="username"
          name="username"
          required
          value={this.state.formControls.username}
          onChange={this.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          error={this.state.errors.username !== ''}
          className="input-color"
          label="User Name"
          margin="normal"
          variant="outlined" />
      </div>
    )
  }

  renderButtons() {
    return (
      <div className="login-button-container">
        <Button
          type="submit"
          name="continue"
          variant="contained"
          size="large"
          classes={{ label: 'login-button-label' }}
          className="login-button">
          Reset Password
        </Button>

      </div>
    )
  }

  render() {
    return (
      <div className="login-input">
        <form onSubmit={this.handleSubmit} className="login-input">
          <FormControl className="form-box" >
            <Grid
              container
              direction="column"
              justify="space-evenly"
              alignItems="center" >
              {this.state.step === 1 ? this.step1Render() : null}
              {this.state.step === 2 ? <SuccessEmailReset /> : null}
              {this.state.step === 2 ? <button className="forgot-button" onClick={this.goBackToSignIn}>Back to Sign In</button> : null}
            </Grid>
            {this.state.step !== 2 && !this.state.loading ? this.renderButtons() : null}
          </FormControl>
          <div className="error-messages">
            {this.state.errors.login}
          </div>
        </form>
        {this.state.loading ? <DotLoader /> : null}
        {this.state.step !== 2 && !this.state.loading ? <button className="forgot-button" onClick={this.goBackToSignIn}>Back to Sign In</button> : null}
      </div >
    )
  }
}

export default ResetPassword;
